module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-11373505-1"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Michael Locher","short_name":"Step Aerobic","lang":"de","start_url":"/","display":"browser","orientation":"any","background_color":"white","legacy":false,"theme_color_in_head":false,"icons":[{"src":"icons/icon.svg","sizes":"any","type":"image/svg+xml"},{"src":"icons/icon.svg","sizes":"512x512","type":"image/svg+xml"},{"src":"icons/icon192.png","sizes":"192x192","type":"image/png","purpose":"any maskable"},{"src":"icons/icon256.png","sizes":"256x256","type":"image/png","purpose":"any maskable"},{"src":"icons/icon384.png","sizes":"384x384","type":"image/png","purpose":"any maskable"},{"src":"icons/icon512.png","sizes":"512x512","type":"image/png","purpose":"any maskable"},{"src":"icons/icon48.png","sizes":"48x48","type":"image/png","purpose":"any maskable"},{"src":"icons/icon72.png","sizes":"72x72","type":"image/png","purpose":"any maskable"},{"src":"icons/icon96.png","sizes":"96x96","type":"image/png","purpose":"any maskable"},{"src":"icons/icon144.png","sizes":"144x144","type":"image/png","purpose":"any maskable"},{"src":"icons/favicon.ico","sizes":"16x16 32x32 48x48 64x64","type":"image/vnd.microsoft.icon"},{"src":"icons/logo.ico","sizes":"16x16 32x32 48x48 64x64 96x96 128x128 256x256","type":"image/vnd.microsoft.icon"}],"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
